import { ISpeaker, ITbFormat } from '../../components/Editor/IEditor';
import { IAppliedFilter } from '../../components/DashboardHeader/ISearch';
import { Map, Attributes, ColumnTypes } from '../../components/Dashboard/IDashboard';
import { SelectionState } from 'draft-js';
import { IPhonems } from '../../components/DictionaryModal/IDictionaryModal';
import { IContextString } from '../../components/Dictionary/ContextLine';
import { IAddToDictWord, IPronunciation } from '../../api/DictionaryService';
import { IActiveUserQuotaChunk } from '../../api/UserService';
import { SortDirectionType } from 'react-virtualized';
import { ISttPipelines } from '../../components/Home/ISettings';
interface IPipelineModelFeature {
  value: boolean;
  enabled: boolean;
}

interface IPipelineModelParameter {
  value: boolean;
  enabled: boolean;
}
export interface IPipelineModel {
  name: string,
  //features
  onlineAsr?: boolean,
  offlineAsr?: boolean,
  dictatedCommands?: IPipelineModelFeature,
  dictatedPunctuations?: IPipelineModelFeature,

  //parameters
  enableUnks?: IPipelineModelParameter,
  enableInterims?: IPipelineModelParameter,
  enableSd?: IPipelineModelParameter,

  //additionalParameters
  isUpdatable?: boolean,
  isUpdating?: boolean,

  serviceProvider: string,
}

export interface IActiveConfiguration {
  serviceProvider: string | null,
  language: string | null,
  domain: string | null,
  model: IPipelineModel | null,
}

//Punctuation
export interface IAutomaticPunctuationModel {
  name: string;
  serviceProvider: string;
  //features

  //parameters
  enableSplitToSentences?: IPipelineModelParameter,
}
export interface IPunctuator {
  language: string | null,
  domain: string | null,
  model: IAutomaticPunctuationModel | null,
  toggled: boolean;
}

//Truecaser
export interface ITrueCaserModel {
  name: string;
  serviceProvider: string;
  //features
  //parameters
}
export interface ITrueCaser {
  language: string | null,
  domain: string | null,
  model: ITrueCaserModel | null,
  toggled: boolean;
}

//inverse text normalization
export interface IInverseTextNormalizationModel {
  name: string;
  serviceProvider: string;
  //features
  //parameters
}
export interface IInverseTextNormalizator {
  language: string | null,
  domain: string | null,
  model: IInverseTextNormalizationModel | null,
  toggled: boolean;
}
export interface ISnack {
  open: boolean;
  message: string;
  variant: 'success' | 'error';
}

export interface IEditorLock {
  sessionLockKey: string | null;
  sessionId: number | null;
  recordingId: number | null;
  refreshAfter: number | null;
  editTicket: string | null;
  versionName: string | null;
}

export enum UserRoleEnums {
  MODEL_UPDATER_READER = 'MODEL_UPDATER_READER', //this one gets model info in future
  FILE_UPLOADER = 'FILE_UPLOADER',
  DICTIONARY_WRITER = 'DICTIONARY_WRITER',
  DICTIONARY_READER = 'DICTIONARY_READER',
  offline_access = 'offline_access',
  LIVE_STREAMER = 'LIVE_STREAMER',
  SUBSTITUTOR_READER = 'SUBSTITUTOR_READER',
  FRAMEWORK_CHANGER = 'FRAMEWORK_CHANGER',
  ADMIN = 'ADMIN',
  uma_authorization = 'uma_authorization',
  MODEL_UPDATER_WRITER = 'MODEL_UPDATER_WRITER', //this one gets model info in future
  EDITOR_USER = 'EDITOR_USER',
  PIPELINE_ONLINE_API = 'PIPELINE_ONLINE_API',
  PIPELINE_OFFLINE_API = 'PIPELINE_OFFLINE_API',
  SESSION_READ_DISCARDED = 'SESSION_READ_DISCARDED',
  SESSION_READ_OTHER = 'SESSION_READ_OTHER',
  SESSION_WRITE_OTHER = 'SESSION_WRITE_OTHER',
  USER_READ_OTHER = 'USER_READ_OTHER',
  SESSION_HARD_DELETE = 'SESSION_HARD_DELETE',
  API = 'API',
}
export interface IUser {
  isAuthenticated: boolean;
  username: string;
  accessToken?: string;
  refreshToken?: string;
  userRoles: UserRoleEnums[];
  isEditorUser?: boolean;
  idToken?: string;
  group: string;
}

export enum IWsAction {
  IDLE = 'IDLE',
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
  RECONNECT = 'RECONNECT',
}

export enum FontSize {
  Biggest = '22px',
  Big = '18px',
  Medium = '14px',
  Small = '10px',
}

export enum EditorModeEnums {
  EDIT_MODE = 'EDIT_MODE',
  PLAYING_MODE = 'PLAYING_MODE',
  RECORDING_MODE = 'RECORDING_MODE',
  TRANSCRIBING_UPLOAD_MODE = 'TRANSCRIBING_UPLOAD_MODE',
  TB_UPLOAD_MODE = 'TB_UPLOAD_MODE',
}

export enum AnimButtonStatesEnum {
  NORMAL = 'NORMAL',
  WAITING_TO_START = 'WAITING_TO_START',
  WAITING_TO_STOP = 'WAITING_TO_STOP',
  RECORDING = 'RECORDING',
}

export enum EditorVariantEnum {
  LIVE = 'LIVE',
  EDITOR = 'EDITOR',
}

export interface ILoadContentToEditor {
  recFinishedStartLoadingNewEditorState?: boolean;
  recStartedLoadTextFromEditor?: boolean;
}

export interface IRefectTokens {
  refetchCount: number;
  refetchTime: number | null;
}

export interface IAddColumnMenu {
  anchor: null | SVGSVGElement;
  id: string;
}

export interface IUpdateEditorState {
  update: boolean;
  newText?: string;
  rangeToReplace?: number[];
  entityToMerge?: string;
  blockKey?: string;
}

export interface ISessionLabel {
  id: number;
  code: string;
  isDefault: boolean;
  isAssigned: boolean;
  color: string;
}
export interface ISortObject {
  columnName: string;
  type: SortDirectionType;
}

export interface IPageable {
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  totalElements: number;
}

// Also used for table (only normal & none)
export enum DashboardRefreshState {
  NORMAL = 'NORMAL', // We wish to refresh the dashboard
  ZERO = 'ZERO', // We wish to refresh the dashboard, but also set pagination to 0
  NONE = 'NONE', // We don't need to refresh the dashboard
}

export interface IHeaderColumn {
  id: Attributes;
  width: number;
  order: number; // in which order are headers displayed
  shown: boolean;
  filtered: boolean;
  sortable: boolean;
  label: string;
  type: ColumnTypes;
}

export enum SpeakersModalTypeEnum {
  NORMAL = 'NORMAL',
  ADD_SPEAKER_TO_SELECTION = 'ADD_SPEAKER_TO_SELECTION',
}

export interface ISpeakersModal {
  showModal: boolean;
  speaker: ISpeaker | null;
  blockKey: string | null;
  modalType: SpeakersModalTypeEnum;
  editorSelection: SelectionState | null;
  // blockData: any;
}

export type IHeaderWidth = Map<number>;
export type IHeader = Map<IHeaderColumn>;

export interface IDictionary {
  phones?: IPhonems | null;
  contextString: IContextString | null;
  searchBy: 'word' | 'pronunciation';
  searchedString: null | string;
  caseSensitive: boolean;
  pageSize: number;
  words: IAddToDictWord[] | undefined;
  autoSave: IAddToDictWord | null;
}

export interface IHomeFlowStates {
  uploadFlowInProgress?: boolean;
  liveFlowInProgress?: boolean;
}

export interface IDashboardFilterCommands {
  showDeletedSessions: boolean
}

export enum ConsumtionModalTemplate {
  UPLOAD_LIMIT = "UPLOAD_LIMIT",
  LIMIT = "LIMIT"
}

export interface IConsumtionModal {
  visible: boolean;
  template: ConsumtionModalTemplate | undefined;
}

export interface ISpeakersSettingsModal {
  visible: boolean;
  numberOfSpeakers: number | null;
  minNumberOfSpeakers: number | null;
  maxNumberOfSpeakers: number | null;
  retranscription: boolean;
}

export interface IAudioInfo {
  url: string;
  loadNew: boolean
}

export interface IPronoun {
  pronaun: IPronunciation | null;
  pronaunIndex: number;
  wordIndex: number;
}

export enum TableHeaders {
  status = "status",
  name = "name",
  visibility = "visibility", //no
  labels = "labels", //no
  recordedMs = "recordedMs",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  createdByUser = "createdByUser", //no
  sources = "sources", //no
}

export enum SortDirection {
  asc = 'ASC',
  desc = 'DESC',
}

export interface ITableHeader {
  key: TableHeaders,
  label: string,
  width: number,
  sortDirection: SortDirection | null;
  hidden: boolean;
}

export interface IStore {
  //   variant: EditorVariantEnum;
  doDictation: boolean;
  fontsLoaded: boolean;
  dictionary: IDictionary;
  isTranscript: boolean;
  isPlaying: boolean;
  dashboardFilterCommands: any;
  isAudioPlaying: boolean;
  loadContentToEditor: ILoadContentToEditor;
  currentTime: number;
  uploadedFileDuration: number | null;
  speakersModal: ISpeakersModal;
  audioInfo: IAudioInfo;
  manuallyUpdateEditorState: IUpdateEditorState;
  homeFlowStates: IHomeFlowStates;
  tbFormat: ITbFormat;
  youtubeLink: string | null;
  showConfidence: boolean;
  editorMode: EditorModeEnums | null;
  clickedTime: number | null;
  fontSize: string;
  headerIsExtended: boolean;
  sessionName: string;
  isSessionDiscarded: boolean;
  activeWordId: string;
  makeBlocks: boolean;
  saveToDisk: boolean;
  user: IUser | null;
  authOkGetConfig: boolean;
  sessionId: string;
  urlName: string;
  animButtonStates: AnimButtonStatesEnum;
  //   checkWebSocketState: boolean;
  lastOriginalText: string;
  snack: ISnack;
  wsAction: IWsAction;
  entitiesKeysToMerge: string[];
  //   dModelId: number;
  validRedirect: boolean;
  dashboardFilters: IAppliedFilter[];
  addColumnsMenu: IAddColumnMenu;
  dashboardExpendedRow: number | null;
  sessionsLabels: ISessionLabel[];
  defaultLabels: ISessionLabel[];
  dashboardRefresh: DashboardRefreshState;
  dashboardSort: ISortObject;
  dashboardPagination: IPageable;
  dashboardHeader: IHeader | null;
  dashboardHeaderWidths: IHeaderWidth | null;
  dashboardTableWidth: number | null;
  dashboardTableRefresh: DashboardRefreshState;
  activeConfiguration: IActiveConfiguration;
  activePunctuator: IPunctuator;
  activeTrueCaser: ITrueCaser;
  activeInverseTextNormalizator: IInverseTextNormalizator;
  editorLock: IEditorLock,
  activeQuota: IActiveUserQuotaChunk | null;
  consumptionModal: IConsumtionModal;
  speakerSettingsModal: ISpeakersSettingsModal;
  speakerUpdated: boolean;
  diarization: boolean;
  triggerStopRecording: boolean;
  tableScrollPosition: number;
  isDashboardLoading: boolean;
  configuration: ISttPipelines;
  tableHeaders: ITableHeader[];
  isDashboardReady: boolean;
}
