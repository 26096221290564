import { IFinal, ITranscriptWord } from "../components/Editor/IEditor";
import {
    IV3RecievedTranscript,
    IReceivedTranscript,
    IV3RecievedToken,
    IV3RecievedMessage
} from "../components/Libraries/ILibraries";

const isPunctuation = (textValue: string) => textValue === "." || textValue === "?" || textValue === "!" || textValue === "," || textValue === ":" || textValue === ";"
const isPunctuationCommand = (textValue: string) => textValue === "<.>" || textValue === "<?>" || textValue === "<!>" || textValue === "<,>" || textValue === "<:>" || textValue === "<;>"
const convertPunctuationCommand = (textValue: string) => {
    switch (textValue) {
        case "<.>": return "."
        case "<?>": return "?"
        case "<!>": return "!"
        case "<,>": return ","
        default: return textValue
    }
}
const isCommand = (textValue: string) => textValue.startsWith("<") && textValue.endsWith(">")

export const convertFromVersion3ToVersion2File = (data: IV3RecievedTranscript[]): IFinal[] => {
    let capitalizeNext = false
    let spaceBeforeNext = false

    return data.map(transcript => {
        return {
            content: transcript.tokens.map(token => {
                if (isPunctuation(token.text) || isPunctuationCommand(token.text)) {

                    /*if (transcript.isFinal) {
                        capitalizeNext = true
                    }*/

                    spaceBeforeNext = !token.isRightHanded

                        
                    return {
                        startTime: (transcript.startTimeMs + token.startOffsetMs) / 1000,
                        endTime: (transcript.startTimeMs + token.endOffsetMs) / 1000,
                        confidence: token.confidence ? token.confidence : 0,
                        text: isPunctuationCommand(token.text) ? convertPunctuationCommand(token.text) : token.text,
                        spaceBefore: spaceBeforeNext && !token.isLeftHanded,
                        speakerCode: token.speakerCode
                    }
                } else if (!isCommand(token.text)) {
                    let text = capitalizeNext ? token.text.toUpperCase() : token.text
                    spaceBeforeNext = true;
                    capitalizeNext = false;
                    return {
                        startTime: (transcript.startTimeMs + token.startOffsetMs) / 1000,
                        endTime: (transcript.startTimeMs + token.endOffsetMs) / 1000,
                        confidence: token.confidence ? token.confidence : 0,
                        text,
                        spaceBefore: spaceBeforeNext,
                        speakerCode: token.speakerCode
                    }
                } else {
                    return {
                        startTime: (transcript.startTimeMs + token.startOffsetMs) / 1000,
                        endTime: (transcript.startTimeMs + token.endOffsetMs) / 1000,
                        confidence: token.confidence ? token.confidence : 0,
                        text: token.text,
                        speakerCode: token.speakerCode
                    }
                }
            })
        }
    })
}
export const convertFromVersion3ToVersion2 = (data: IV3RecievedMessage, shouldCapitalizeFirst: boolean): IReceivedTranscript => {
    let capitalizeNext = shouldCapitalizeFirst
    let spaceBeforeNext = false

    return {
        type: data.type,
        isFinal: data.textSegment.isFinal,
        decoded: data.textSegment.endTimeMs / 1000,
        transcript: {
            id: 0,
            content: data.textSegment.tokens.map(token => {
                if (isPunctuation(token.text) || isPunctuationCommand(token.text)) {

                    if (data.textSegment.isFinal && token.text !== "," && token.text !== "<,>") {
                        capitalizeNext = true
                    }

                    spaceBeforeNext = !token.isRightHanded

                    return {
                        startTime: (token.startOffsetMs) / 1000,
                        endTime: (token.endOffsetMs) / 1000,
                        confidence: token.confidence ? token.confidence : 0,
                        text: isPunctuationCommand(token.text) ? convertPunctuationCommand(token.text) : token.text,
                        spaceBefore: false//spaceBeforeNext && !token.isLeftHanded
                        
                    } as ITranscriptWord
                } else if (!isCommand(token.text)) {

                    let text = capitalizeNext ? token.text.charAt(0).toUpperCase() + token.text.slice(1) : token.text
                    spaceBeforeNext = true;
                    capitalizeNext = false;

                    return {
                        startTime: (token.startOffsetMs) / 1000,
                        endTime: (token.endOffsetMs) / 1000,
                        confidence: token.confidence ? token.confidence : 0,
                        text,
                        spaceBefore: spaceBeforeNext,
                    } as ITranscriptWord
                } else {
                    return {
                        startTime: (token.startOffsetMs) / 1000,
                        endTime: (token.endOffsetMs) / 1000,
                        confidence: token.confidence ? token.confidence : 0,
                        text: token.text,
                    } as ITranscriptWord
                }

            })
        }
    } as IReceivedTranscript
}

export const convertFromVersion2ToVersion3 = (data: IReceivedTranscript): IV3RecievedTranscript => {
    const firstContent = data.transcript.content[0] as ITranscriptWord
    const lastContent = data.transcript.content[data.transcript.content.length - 1] as ITranscriptWord

    return {
        type: data.type,
        isFinal: data.isFinal,
        startTimeMs: firstContent.startTime * 1000,
        endTimeMs: lastContent.endTime * 1000,
        tokens: (data.transcript.content as ITranscriptWord[]).map(transcript => {
            return {
                startOffsetMs: (transcript.startTime - firstContent.startTime) * 1000,
                endOffsetMs: (transcript.endTime - firstContent.startTime) * 1000,
                text: transcript.text,
            } as IV3RecievedToken
        })
    } as IV3RecievedTranscript
} 