import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export function ArrowDropDownIcon(props: any) {
    return (
        <SvgIcon onClick={event => props.onClick(event)} className='version-opener' style={{maskType: 'alpha'}}>
            <svg className='version-opener' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className='version-opener' d="M10.7495 12.0001L6.69104 8.00012H14.808L10.7495 12.0001Z" fill="#2B8DFF" />
            </svg>

        </SvgIcon>
    );
}