import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { unlockSession } from "../api/SessionsService";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { setIsDashboardReady } from "../redux/features/app/app";

const BackButtonListener = () => {
  const location = useLocation();
  const previousPathRef = useRef<string>(location.pathname);
  
  const editorLock = useAppSelector(state => state.app.editorLock);

  const dispatch = useAppDispatch();
  
  useEffect(() => {
    const handleExitEditor = async () => {
        if (!editorLock.sessionId) return;

        try {
          await unlockSession(editorLock.sessionId, editorLock.sessionLockKey)
        } catch (error) {
          console.log(error);
        }

        dispatch(setIsDashboardReady(true))
        previousPathRef.current = location.pathname
      }

      if (previousPathRef.current === "/editor" && location.pathname !== "/editor") {
        handleExitEditor();
      }

      previousPathRef.current = location.pathname;
  }, [location]);

  return null;
};

export default BackButtonListener;
