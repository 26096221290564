import React from 'react'
import { useState } from 'react'
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './tablev3.css'
import '../Home/classess.css'
import { IHistorySession } from './IDashboard';
import { getMergedEditorContent, mergeSessions } from '../../api/SessionsService';
import { convertFromRaw, EditorState } from 'draft-js';
import NewTable from './NewTable';
import { Button } from '@mui/material';
import useDownload from '../../hooks/useDownload';


const V3Table = () => {
    const [data, setData] = useState<IHistorySession[]>([]);

    const { handleDownloadDocxFromState } = useDownload();

    const updateData = (newData: IHistorySession[]) => setData(newData);

    const joinTaggedSessions = async () => {
        const taggedIds = data.filter(session => session.tagged).map(session => session.id)

        try {
            await mergeSessions(taggedIds);
        } catch (error) {
            console.log(error)
        }
    }

    const mergeContent = async () => {
        const taggedIds = data.filter(session => session.tagged).map(session => session.id)
        
        const parameters = new URLSearchParams();

        taggedIds.forEach(id => {
            parameters.append("session-id", id.toString())
        })
        
        try {
            const test = await getMergedEditorContent(parameters);
            const notRaw = convertFromRaw(test.data.rawContentState)
            const editorState = EditorState.createWithContent(notRaw)
            await handleDownloadDocxFromState(editorState)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            {/*<Button onClick={joinTaggedSessions}>JOIN SESSIONS</Button>
            <Button onClick={mergeContent}>JOIN CONTENT</Button>
            <DatePicker selected={startDate} onChange={onDateChange} />*/}
            <DndProvider backend={HTML5Backend}>
                <NewTable updateData={updateData} data={data} />
            </DndProvider>
        </div>
    )
}

export default V3Table;